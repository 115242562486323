<template>
    <div>
        <v-menu
            ref="startDateMenu"
            v-model="startDateMenu"
            lazy
            :close-on-content-click="false"
            transition="scale-transition"
            :return-value.sync="startDate">
            <v-autocomplete
                slot="activator"
                label="Billing Month"
                :placeholder="startDate"
                class="styled-field"
                readonly />
            <v-date-picker
                v-model="startDate"
                :max="maxMonth"
                type="month"
                width="337"
                @input="$refs.startDateMenu.save(startDate)" />
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        value: {
            type: Object,
            default: null,
            validator(value) {
                return (value.start && value.end);
            }
        },
        monthKey: {
            type: String,
            default: 'end'
        }
    },
    data() {
        return {
            startDate: null,
            startDateMenu: null,
            maxMonth: moment().format('YYYY-MM'),
            activator: null,
        };
    },
    watch: {
        value() {
            // Use the month from the end date of the provided range
            this.startDate = moment(this.value[this.monthKey]).format('YYYY-MM');
        },
        startDate() {
            // Ensure the date range is always synced up with the start date
            this.dateRange = this.getMonthDateRange(this.startDate);
            // Send the input so it can be used externally
            this.$emit('input', this.dateRange);
        }
    },
    created() {
        this.initValue();
    },
    methods: {
        /**
         * Helper function to handle the value prop and input event to help
         * parent components navigate defaults and allow users to use v-model
         */
        initValue() {
            // If we're passed a value for the key use it so the parent can override
            if (this.value !== null) {
                // Set the start date to the end of the month
                this.startDate = moment(this.value[this.monthKey]).format('YYYY-MM');
                return;
            }
            // Otherwise export the date range so it can be used externally
            this.$emit('input',  this.dateRange);
            //  Set the range will null so moment uses the current month
            this.dateRange = this.getMonthDateRange(null);
        },
        getMonthDateRange(date) {
            return {
                start: moment(date).startOf('month').format('YYYY-MM-DD'),
                end: moment(date).endOf('month').format('YYYY-MM-DD')
            };
        }
    }
};
</script>