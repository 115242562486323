var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-interface',{scopedSlots:_vm._u([{key:"interface-toolbar",fn:function(){return [_c('month-picker',{model:{value:(_vm.currentDateRange),callback:function ($$v) {_vm.currentDateRange=$$v},expression:"currentDateRange"}})]},proxy:true},{key:"interface-heading",fn:function(){return [_vm._v(" Billing ")]},proxy:true}])},[_c('styled-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Billing by Product ")]},proxy:true},{key:"action-buttons",fn:function(){return [_c('action-button',{attrs:{"icon":"download"},on:{"click":_vm.exportCSV}},[_vm._v(" Export CSV ")])]},proxy:true}])},[_c('div',{staticClass:"groups-picker"},[_c('div',{staticClass:"groups-picker__holder"},[_c('groups-picker-global',{attrs:{"disabled":_vm.loading}})],1)]),(_vm.loading || _vm.groupsLoading)?_c('div',{staticClass:"text-xs-center pa-5"},[_c('loader',{attrs:{"label":"Loading Billing Data"}})],1):[_c('div',{staticClass:"layout px-4 py-4 justify-space-around row align-end mb-4 wrap"},[_c('div',{class:{
                        'stats-element': true,
                        'active': (_vm.activeFilter === null)
                    }},[_c('div',[_c('span',{staticClass:"text-uppercase"},[_vm._v(" TOTAL PRODUCTS ")]),_c('h4',{staticClass:"font-weight-medium-bold light-blue--text"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.filterByProduct(null)}}},[_vm._v(" "+_vm._s(_vm.dealerProducts.length)+" ")])])])]),_vm._l((_vm.products),function(product,index){return _c('div',{key:index,class:{
                        'stats-element': true,
                        'active': (_vm.activeFilter === product.product_id)
                    }},[_c('div',[_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(product.product)+" ")]),_c('h4',{staticClass:"font-weight-medium-bold text-uppercase"},[_c('a',{staticClass:"text--green",on:{"click":function($event){$event.preventDefault();return _vm.filterByProduct(product.product_id)}}},[_vm._v(" "+_vm._s(product.count)+" ")])])])])})],2),_c('custom-table',{attrs:{"name":"billing","min-width":"1200px","items":_vm.dealerProductsDisplayed,"fields":_vm.fields,"loading":_vm.loading,"notification":_vm.notification,"notification-type":_vm.notificationType,"rows-per-page":50,"hide-sort":"","slim-cells":""}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }